import { KEYBOARD_KEY } from '~/constants/event'

export function matchIsMobileOperatingSystem(): boolean {
  const { userAgent } = navigator

  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent
  )
}

export function handleAccessibleKeyDown(
  callback: (event: React.KeyboardEvent) => void
) {
  return (event: React.KeyboardEvent) => {
    if (
      event.code === KEYBOARD_KEY.space ||
      event.code === KEYBOARD_KEY.enter
    ) {
      return callback(event)
    }

    return undefined
  }
}

export function matchIsBrowser() {
  return typeof window !== 'undefined'
}
